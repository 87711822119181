@charset "utf-8";

$body-size: 14px;

/* -----------------------
   Bulma Base
   -----------------------
 */
@import '../node_modules/bulma/sass/utilities/_all';
@import '../node_modules/bulma/sass/base/_all';
@import '../node_modules/bulma/sass/helpers/_all';

/* -----------------------
   Elements
   -----------------------
@import "../node_modules/bulma/sass/elements/box.sass"
@import "../node_modules/bulma/sass/elements/content.sass"
@import "../node_modules/bulma/sass/elements/icon.sass"
@import "../node_modules/bulma/sass/elements/image.sass"
@import "../node_modules/bulma/sass/elements/progress.sass"
@import "../node_modules/bulma/sass/elements/table.sass"
@import "../node_modules/bulma/sass/elements/other.sass"
 */
@import "../node_modules/bulma/sass/elements/container.sass";
@import "../node_modules/bulma/sass/elements/button.sass";
@import "../node_modules/bulma/sass/elements/notification.sass";
@import "../node_modules/bulma/sass/elements/tag.sass";
@import "../node_modules/bulma/sass/elements/title.sass";

/* -----------------------
   Form
   -----------------------
 */
@import "../node_modules/bulma/sass/form/shared.sass";
@import "../node_modules/bulma/sass/form/input-textarea.sass";
@import "../node_modules/bulma/sass/form/checkbox-radio.sass";
@import "../node_modules/bulma/sass/form/select.sass";
@import "../node_modules/bulma/sass/form/file.sass";
@import "../node_modules/bulma/sass/form/tools.sass";

/* -----------------------
   Components
   -----------------------
@import '../node_modules/bulma/sass/components/breadcrumb.sass';
@import '../node_modules/bulma/sass/components/card.sass';
@import '../node_modules/bulma/sass/components/level.sass';
@import '../node_modules/bulma/sass/components/list.sass';
@import '../node_modules/bulma/sass/components/media.sass';
@import '../node_modules/bulma/sass/components/menu.sass';
@import '../node_modules/bulma/sass/components/navbar.sass';
@import '../node_modules/bulma/sass/components/pagination.sass';
@import '../node_modules/bulma/sass/components/panel.sass';
@import '../node_modules/bulma/sass/components/tabs.sass';
 */
@import '../node_modules/bulma/sass/components/message.sass';
@import '../node_modules/bulma/sass/components/modal.sass';
@import '../node_modules/bulma/sass/components/dropdown.sass';

/* -----------------------
   Grid
   -----------------------
@import '../node_modules/bulma/sass/grid/_all';
 */

/* -----------------------
   Layout
   -----------------------
@import '../node_modules/bulma/sass/layout/hero.sass';
@import '../node_modules/bulma/sass/layout/footer.sass';
 */
@import '../node_modules/bulma/sass/layout/section.sass';

/* -----------------------
   Bulma-Checkradio
   -----------------------
 */
@import '../node_modules/bulma-checkradio/src/sass/index';

/* -----------------------
   Apps
   -----------------------
 */
@import './components/all';

/* -----------------------
   Custom
   -----------------------
 */
// NOTE:- コンテストごとの独自のカラー定義
@mixin contest($contest) {
  // Loading
  .Loading > div > div {
    border: 4px solid $contest !important;
  }

  // a
  a {
    color: $contest;
  }

  // select
  .select:not(.is-multiple):not(.is-loading)::after {
    border-color: $contest;
  }
  .is-checkradio[type="checkbox"].is-link:hover:not([disabled]) + label::before,
  .is-checkradio[type="checkbox"].is-link:hover:not([disabled]) + label:before,
  .is-checkradio[type="checkbox"].is-link:checked + label::after,
  .is-checkradio[type="checkbox"].is-link:checked + label:after {
    border-color: $contest !important;
  }

  // .button.is-link
  .button.is-link {
    background-color: $contest;
    border-color: transparent;
    color: #fff;
  }

  .button.is-link:hover,
  .button.is-link.is-hovered {
    background-color: darken($contest, 2.5%);
    border-color: transparent;
    color: #fff;
  }

  .button.is-link:focus,
  .button.is-link.is-focused {
    border-color: transparent;
    color: #fff;
  }

  .button.is-link:focus:not(:active),
  .button.is-link.is-focused:not(:active) {
    box-shadow: $button-focus-box-shadow-size rgba($contest, 0.25); //$button-focus-box-shadow-size $button-focus-box-shadow-color
  }

  .button.is-link:active,
  .button.is-link.is-active {
    background-color: darken($contest, 5%);
    border-color: transparent;
    color: #fff;
  }

  .button.is-link[disabled],
  fieldset[disabled] .button.is-link {
    background-color: $contest;
    border-color: transparent;
    box-shadow: none;
  }

  .button.is-link.is-inverted {
    background-color: #fff;
    color: $contest;
  }

  .button.is-link.is-inverted:hover,
  .button.is-link.is-inverted.is-hovered {
    background-color: #f2f2f2;
  }

  .button.is-link.is-inverted[disabled],
  fieldset[disabled] .button.is-link.is-inverted {
    background-color: #fff;
    border-color: transparent;
    box-shadow: none;
    color: $contest;
  }

  .button.is-link.is-loading::after {
    border-color: transparent transparent #fff #fff !important;
  }

  .button.is-link.is-outlined {
    background-color: transparent;
    border-color: $contest;
    color: $contest;
  }

  .button.is-link.is-outlined:hover,
  .button.is-link.is-outlined.is-hovered,
  .button.is-link.is-outlined:focus,
  .button.is-link.is-outlined.is-focused {
    background-color: $contest;
    border-color: $contest;
    color: #fff;
  }

  .button.is-link.is-outlined.is-loading::after {
    border-color: transparent transparent $contest $contest !important;
  }

  .button.is-link.is-outlined.is-loading:hover::after,
  .button.is-link.is-outlined.is-loading.is-hovered::after,
  .button.is-link.is-outlined.is-loading:focus::after,
  .button.is-link.is-outlined.is-loading.is-focused::after {
    border-color: transparent transparent #fff #fff !important;
  }

  .button.is-link.is-outlined[disabled],
  fieldset[disabled] .button.is-link.is-outlined {
    background-color: transparent;
    border-color: $contest;
    box-shadow: none;
    color: $contest;
  }

  .button.is-link.is-inverted.is-outlined {
    background-color: transparent;
    border-color: #fff;
    color: #fff;
  }

  .button.is-link.is-inverted.is-outlined:hover,
  .button.is-link.is-inverted.is-outlined.is-hovered,
  .button.is-link.is-inverted.is-outlined:focus,
  .button.is-link.is-inverted.is-outlined.is-focused {
    background-color: #fff;
    color: $contest;
  }

  .button.is-link.is-inverted.is-outlined.is-loading:hover::after,
  .button.is-link.is-inverted.is-outlined.is-loading.is-hovered::after,
  .button.is-link.is-inverted.is-outlined.is-loading:focus::after,
  .button.is-link.is-inverted.is-outlined.is-loading.is-focused::after {
    border-color: transparent transparent $contest $contest !important;
  }

  .button.is-link.is-inverted.is-outlined[disabled],
  fieldset[disabled] .button.is-link.is-inverted.is-outlined {
    background-color: transparent;
    border-color: #fff;
    box-shadow: none;
    color: #fff;
  }
}

// NOTE: Application
// Fresh Campus Contest
.is-app-3a8b6033811498408cfee867479b8f1c9587a7a6057a0cc60450899940b533e6 {
  $contest: #fd95ac;
  @include contest($contest);
}

// Miss Circle Contest
.is-app-a57f9b573acb4cff3a2c6ab80c05a3ba0cfda2d8f449b90ec7659c952ee7839c {
  $contest: #19c5db;
  @include contest($contest);
}

// NOTE:- Utils
.has-text-marker {
  font-weight: bolder;
  background: linear-gradient(transparent 70%,#fffacc 0);
}
